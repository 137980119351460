<template>
  <v-container class="pa-0" min-width="100%">
    <div class="viewport">
      <v-tabs v-model="activeTab" grow fixed-tab optional show-arrows class="secondary viewport">
        <template v-for="(t, index) in types" :key="t.value">
          <v-tab v-if="!legacyTypes.includes(t.value)" @click="(type = index), (realmsTab = '')">
            {{ t.text }}
          </v-tab>
        </template>

        <v-menu location="end" offset-y>
          <template #activator="{ props }">
            <v-btn
              append-icon="mdi-menu-down"
              variant="plain"
              height="100%"
              class="align-self-center text-none"
              v-bind="props"
            >
              Realms
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(t, index) in realmTypes"
              :key="index"
              :model-value="t.value"
              @click="handleRealmsTabClick(t.value)"
            >
              {{ t.text }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tabs>
    </div>

    <v-card :class="{ viewport: !expanded }">
      <v-icon
        :icon="expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
        style="position: absolute; right: 12px; top: 12px"
        @click="expanded = !expanded"
      />

      <v-data-table
        v-if="!types[type].value.includes('-') && headers"
        :loading="dataWait"
        :items="files"
        :search="types[type].value"
        :headers="headers"
        :items-per-page="100"
        :custom-filter="filterFiles"
        :sort-by="[{ key: 'lastModified', order: 'desc' }]"
        item-key="index"
        disable-pagination
        hide-default-footer
        class="elevation-1"
      >
        <template #[`item.lastModified`]="{ item }">
          <div v-if="item.lastModified" class="text-no-wrap">
            {{ formatDateTime(item.lastModified, 'HH:mm:ss - DD MMM YYYY') }}
          </div>
        </template>
        <template #[`item.os`]="{ item }">
          <div class="text-no-wrap">
            {{ fileOS(item) }}
          </div>
        </template>
        <template #[`item.ring`]="{ item }">
          <div class="text-no-wrap">
            {{ ringLabel(item.key) }}
          </div>
        </template>
        <template #[`item.task`]="{ item }">
          <div class="text-no-wrap" :title="type === 1 ? item.key.split('/')[3] : ''">
            {{ fileTask(item) }}
          </div>
        </template>
        <template #[`item.size`]="{ item }">
          <div class="text-no-wrap">
            {{ formatFileSize(item.size!) }}
          </div>
        </template>
        <template #[`item.file`]="{ item }">
          <HoverCopy :data="fileName(item)" :message="'Copy filename to clipboard'" />
        </template>
        <template #[`item.key`]="{ item }">
          <div style="white-space: nowrap">
            <template v-if="sensitiveDataToggleSupported(item)">
              <v-btn
                v-if="isSensitiveDataVisibleForFile(item)"
                icon
                dark
                color="green"
                @click="hideSensitiveData(item)"
              >
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-icon dark v-bind="props">mdi-eye</v-icon>
                  </template>
                  <span>Sensitive data will be present</span>
                </v-tooltip>
              </v-btn>
              <v-btn v-else icon dark color="red" @click="showSensitiveData(item)">
                <v-tooltip location="bottom">
                  <template #activator="{ props }">
                    <v-icon dark v-bind="props">mdi-eye-off</v-icon>
                  </template>
                  <span>Sensitive data will be anonymized</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-btn icon @click.stop="downloadFile(item)">
              <v-icon>mdi-cloud-download</v-icon>
            </v-btn>
          </div>
        </template>
        <template #[`item.type`]="{ item }">
          <div v-if="false">
            {{ item.type }}
          </div>
        </template>
      </v-data-table>

      <Orbs v-if="types[type].value === 'orb-streams'" :env="env" :uuid="uuid" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'
  import { getValue } from 'firebase/remote-config'

  import { DateTime } from '#mixins/dateTime'

  import { anonymizedFileDownloadLimitBytes, fileHeaders, fileTypes, legacyFileTypes } from '#views/users/constants'

  import { DataFile } from '#views/users/types'

  import { formatFileSize } from '#utils/files/formatFileSize'

  import { FilesStore } from '#stores'

  @Component
  class Files extends mixins(DateTime) {
    @Prop() public env!: string
    @Prop() public uuid!: string
    @Prop() public rings!: any[]

    public filesStore = new FilesStore()

    public type = 0
    public activeTab = 0
    public realmsTab: string = ''

    public expanded = false

    public types = fileTypes
    public legacyTypes = legacyFileTypes
    public headers = fileHeaders

    public get realmTypes() {
      return this.types.filter((t) => t.value.includes('realm'))
    }

    public get files() {
      return this.filesStore.files || []
    }

    public get dataWait() {
      return this.filesStore.dataWait
    }

    @Watch('uuid')
    protected onUUIDChanged(_val: string, _oldVal: string) {
      this.listFiles()
    }

    @Watch('expanded')
    protected onExpandedChanged(val: string, _oldVal: string) {
      const expands = JSON.parse(localStorage.OuraExpandedViews || '{}')

      expands.files = val

      localStorage.OuraExpandedViews = JSON.stringify(expands)
    }

    public created() {
      logEvent(this.$analytics, 'page_view', {
        page_title: 'Data Files',
        page_location: window.location.toString().split('?')[0],
      })
    }

    public mounted() {
      if (this.uuid) {
        this.listFiles()
      }

      this.expanded = JSON.parse(localStorage.OuraExpandedViews || '{}').files
    }

    public fileOS(file: DataFile) {
      const os = file.key.split('/')[2]

      return os === 'android' ? 'Android' : os === 'ios' ? 'iOS' : 'Not specified'
    }

    public isSensitiveDataVisibleForFile(file: DataFile) {
      // If file size is over supported anonymized size limit....
      if (file.size && file.size > anonymizedFileDownloadLimitBytes) {
        return true
      }

      // ... or anonymization is disabled, always provide plain data file (that contains sensitive data)
      if (!getValue(this.$remoteConfig, 'anonymizedFileDownload').asBoolean()) {
        return true
      }

      const sensitiveStatus = this.filesStore.sensitiveDownloadChoices
      return sensitiveStatus.includes(file.key)
    }

    public sensitiveDataToggleSupported(file: DataFile) {
      // If file size is over supported anonymized size limit....
      if (file.size && file.size > anonymizedFileDownloadLimitBytes) {
        return false
      }

      // ... or anonymization is disabled -> don't show option to download anonymized file
      if (!getValue(this.$remoteConfig, 'anonymizedFileDownload').asBoolean()) {
        return false
      }

      // We could have list of file types which support sensitive data toggling and we could use Enum here to
      // have fixed list of file types.
      return file.type === 'jzlog'
    }

    /**
     * Show sensitive data on downloaded file
     * @param file DataFile
     */
    public showSensitiveData(file: DataFile) {
      this.filesStore.updateDownloadPreference({ downloadSensitiveData: true, file: file })
    }

    /**
     * Hide sensitive data on downloaded file
     * @param file DataFile
     */
    public hideSensitiveData(file: DataFile) {
      this.filesStore.updateDownloadPreference({ downloadSensitiveData: false, file: file })
    }

    public handleRealmsTabClick(value: string) {
      this.type = this.types.findIndex((type) => type.value === value)
      this.activeTab = this.type
    }

    public fileName(file: DataFile) {
      // check if it's SQLite files tab
      if (this.type === 1) {
        return file.key.split('/').slice(4).join('/').split('_').slice(1).join('_')
      }
      return (
        (file.bucket === 'ring-stream'
          ? file.key.split('/').slice(3).join('/')
          : file.key.split('/').slice(4).join('/')) +
        (file.type === 'jzlog' && file.bucket === 'ring-stream' ? '.jzlog' : file.type === 'orb' ? '.orb' : '')
      )
    }

    public fileTask(file: DataFile) {
      const isRingStreamBucket = file.bucket === 'ring-stream'

      switch (file.type) {
        case 'zip':
          // check if fileType is SQLite files tab
          if (this.type === 1) {
            return '...' + file.key.split('/')[3].slice(-5)
          }
          return isRingStreamBucket ? file.key.split('/')[2] : file.key.split('/')[3].replace('process', 'archive')

        case 'jzlog':
          return isRingStreamBucket ? 'process_jzlog' : 'process_jzlog_v1'

        default:
          return 'full'
      }
    }

    public formatFileSize(fileSize: number) {
      return formatFileSize(fileSize)
    }

    public ringLabel(key: string) {
      const parts = key.split('_')

      const mac = parts[parts.length - 1].split('.')[0]

      const index = this.rings.findIndex(
        (ring) => ring.macAddress.replace(/:/g, '').toUpperCase() === mac.toUpperCase(),
      )

      if (index === 0) {
        return `Current (${this.rings[index].macAddress})`
      } else if (index !== -1) {
        return `Old ${index} (${this.rings[index].macAddress})`
      }

      return 'Not specified'
    }

    public filterFiles(_value: any, search: string | null, item: any) {
      return (
        !search ||
        item?.raw?.key.endsWith(search) ||
        item?.raw?.key.endsWith(search + '.zip') ||
        item?.raw?.type === search
      )
    }

    public downloadFile(file: DataFile) {
      file.env = this.env
      file.name = this.fileName(file).includes(this.uuid)
        ? this.fileName(file).split('/').pop()
        : this.uuid + '-' + this.fileName(file).split('/').pop()
      file.anonymizeJzlog = !this.isSensitiveDataVisibleForFile(file)
      this.filesStore.downloadFile(file)

      if (file.type === 'jzlog') {
        logEvent(this.$analytics, 'data_download_jzlog', {
          category: 'File:downloadFile',
          action: 'Click download Jzlog list',
          label: 'Click download Jzlog list',
          page_title: 'Oura user - Data Files tab',
          page_location: window.location.toString().split('?')[0],
        })
      }
    }

    private listFiles() {
      if (this.uuid) {
        this.filesStore.listFiles(this.uuid)
      }
    }
  }

  export default toNative(Files)
</script>

<style lang="scss" scoped>
  :deep(.secondary) {
    .v-tab {
      font-weight: normal !important;
      text-transform: none !important;

      &.v-tab--active {
        font-weight: 500 !important;
      }
    }
    .selected-item {
      background-color: #f0f0f0;
    }
  }
</style>
