<template>
  <!-- eslint-disable vue/v-on-handler-style -->
  <v-container class="pa-0" min-width="100%">
    <v-data-table
      :items="streams"
      :headers="headers.filter((header) => header.key !== 'last_modified' && header.key !== 'file')"
      :items-per-page="100"
      item-key="stream_uuid"
      @click:row="(_event: any, item: any) => (showInfo = item)"
    >
      <template #[`item.key`]="{ item }">
        <div style="white-space: nowrap">
          <v-btn icon @click.stop="downloadFile(item)">
            <v-icon>mdi-cloud-download</v-icon>
          </v-btn>
        </div>
      </template>
      <template #[`item.uploadedAt`]="{ item }">
        {{ formatDateTime(item?.uploadedAt, 'HH:mm:ss - DD MMM YYYY') }}
      </template>
      <template #[`item.label`]="{ item }">
        {{ item?.info?.recording?.label || 'No label set' }}
      </template>
      <template #[`item.fw`]="{ item }">
        {{ item?.info?.ring?.firmware_version || 'Unknown' }}
      </template>
      <template #[`item.collectionId`]="{ item }">
        {{ item?.info?.recording?.collection_id }}
      </template>
      <template #[`item.started`]="{ item }">
        {{ formatDateTime(item?.info?.recording?.time_start, 'HH:mm:ss - DD MMM YYYY') }}
      </template>
      <template #[`item.ended`]="{ item }">
        {{ formatDateTime(item?.info?.recording?.time_end, 'HH:mm:ss - DD MMM YYYY') }}
      </template>
    </v-data-table>

    <v-dialog v-if="showInfo" v-model="showInfo" scrollable width="800">
      <v-card class="sticky">
        <v-card-title>
          Stream info
          <v-spacer />
          <v-btn variant="plain" @click="showInfo = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <!-- eslint-disable-next-line-->
          <div class="diff-container" v-html="htmlDiff" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
  import { format } from 'util'

  import sortKeys from 'sort-keys'

  import * as Diff2Html from 'diff2html'
  import { ColorSchemeType } from 'diff2html/lib/types'

  import { createPatch } from 'diff'

  import { ThemeInstance, useTheme } from 'vuetify'

  import { Component, Prop, Setup, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { DateTime } from '#mixins/dateTime'

  import { orbsHeaders } from '#views/users/constants'

  import { FilesStore } from '#stores'

  @Component
  class Orbs extends mixins(DateTime) {
    @Prop() public env!: string
    @Prop() public uuid!: string

    @Setup(() => useTheme())
    public readonly theme: ThemeInstance | undefined

    public filesStore = new FilesStore()

    public showInfo: any = null

    public headers = orbsHeaders

    public get streams() {
      return this.filesStore.orbStreams
    }

    public get dataWait() {
      return this.filesStore.dataWait || []
    }

    public get htmlDiff() {
      const diff = this.getDiff(this.showInfo)
      const colorScheme = this.theme?.global?.current?.value?.dark ? ColorSchemeType.DARK : ColorSchemeType.LIGHT

      return diff ? Diff2Html.html(diff, { colorScheme: colorScheme }) : ''
    }

    @Watch('uuid')
    protected onUUIDChanged(_val: string, _oldVal: string) {
      this.listStreams()
    }

    public mounted() {
      if (this.uuid) {
        this.listStreams()
      }
    }

    public downloadFile(stream: any) {
      this.filesStore.downloadFile({
        env: this.env,
        key: `stream-full/${stream.userUid}/ORB/${stream.streamUuid}`,
        name: `${stream.userUid}-${stream.streamUuid}.orb`,
        bucket: 'ring-stream',
        anonymizeJzlog: false,
      })
    }

    private listStreams() {
      if (this.uuid) {
        this.filesStore.listOrbStreams(this.uuid)
      }
    }

    private getDiff(file: any) {
      // Get rid of setters & getters because sortKeys breaks otherwise
      file = typeof file == 'object' ? JSON.parse(JSON.stringify(file)) : file

      const stringify = (data: any) => (data ? JSON.stringify(sortKeys(data, { deep: true }), null, '  ') : data)

      const diff = createPatch(file.streamName, '', stringify(file))

      if (diff) {
        return format('diff --git a/%s b/%s\n%s', 'name', 'name', diff)
      }

      return null
    }
  }

  export default toNative(Orbs)
</script>

<style lang="scss" scoped>
  .diff-container {
    position: relative;
  }

  :deep(.d2h-info),
  :deep(.d2h-file-header),
  :deep(.d2h-code-linenumber),
  :deep(d2h-code-line-prefix),
  :deep(.d2h-file-list-wrapper),
  :deep(.d2h-file-name-wrapper) {
    display: none !important;
  }

  :deep(.d2h-code-line) {
    padding: 0 4em !important;
  }

  :deep(.d2h-file-wrapper) {
    margin: 0 !important;
  }
</style>
