<template>
  <v-container class="viewport">
    <v-row class="flex-sm-column flex-md-row">
      <v-col class="d-flex" cols="12" md="8">
        <DateRangePicker
          v-model:start-date="startDate"
          v-model:end-date="endDate"
          :max-day-range="180"
          width="400px"
          @valid="datesAreValid = $event"
        />
      </v-col>
    </v-row>

    <v-alert v-if="summaryDataError" tile class="position-relative mb-4" type="error">
      {{ summaryDataError }}
    </v-alert>
    <v-alert v-if="!isSensitiveDataVisible" tile class="position-relative mb-4" type="info">
      {{ 'Sensitive data not enabled. Most columns will be hidden.' }}
    </v-alert>
  </v-container>

  <v-container class="mt-n8" :class="{ viewport: !expanded }">
    <v-card style="position: relative">
      <v-card-title class="justify-space-between d-flex py-0 pl-0">
        <v-col cols="6">
          <p class="text-overline">Summary data report V2</p>
        </v-col>
      </v-card-title>
      <v-icon
        :icon="expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand'"
        style="position: absolute; right: 16px; top: 16px; z-index: 1"
        @click="expanded = !expanded"
      />
      <v-data-table
        class="elevation-1"
        item-value="day"
        :items="summaryData"
        :items-per-page="100"
        :headers="headers"
        :loading="waitingForData"
        :no-data-text="waitingForData ? 'Loading data...' : 'No data found'"
        :sort-by="[{ key: 'day', order: 'desc' }]"
        :expand-on-click="true"
        :show-expand="true"
        :row-props="
          ({ item }) => {
            return { style: `cursor: ${item.isExpandable ? 'pointer' : 'default'}` }
          }
        "
      >
        <template #[`item.day`]="{ item }">
          <span class="text-no-wrap">{{ formatDateTime(item.day, 'DD MMM YYYY') }}</span>
        </template>
        <template #[`item.bedtimeStart`]="{ item }">
          {{ item.bedtimeStart && $dayjs(item.bedtimeStart).format('H:mm:ss') }}
        </template>
        <template #[`item.bedtimeEnd`]="{ item }">
          {{ item.bedtimeEnd && $dayjs(item.bedtimeEnd).format('H:mm:ss') }}
        </template>
        <template #[`item.nonWearTime`]="{ item }">
          <!-- nonWearTime is in seconds, we want to show minutes -->
          {{ item.nonWearTime ? Math.round(item.nonWearTime / 60) : '0' }}
        </template>
        <template #[`item.highestTemperature`]="{ item }">
          {{ item.highestTemperature && Number.parseFloat(item.highestTemperature).toFixed(2) }}
        </template>
        <template #[`item.timezone`]="{ item }">
          {{
            // timezone is offset in minutes, add plus/minus sign and format as HH:mm e.g. +02:00
            item.timezone &&
            `${item.timezone >= 0 ? '+' : '-'}${$dayjs.utc(Math.abs(item.timezone) * 60 * 1000).format('HH:mm')}`
          }}
        </template>
        <template #[`item.selfTestSuccess`]="{ item }">
          <v-icon v-if="item.selfTestSuccess === true" color="green">mdi-check-circle</v-icon>
          <v-icon v-else-if="item.selfTestSuccess === false" color="red">mdi-close-circle</v-icon>
        </template>
        <template #[`item.fieldTestSuccess`]="{ item }">
          <v-icon v-if="item.fieldTestSuccess === true" color="green">mdi-check-circle</v-icon>
          <v-icon v-else-if="item.fieldTestSuccess === false" color="red">mdi-close-circle</v-icon>
        </template>
        <template #[`item.events`]="{ item }">
          <p v-if="item.events?.length == 1">{{ item.events[0] }}</p>
          <p v-else-if="item.events?.length > 1">
            {{ item.events?.length }} Events
            <v-icon>mdi-chevron-down</v-icon>
          </p>
        </template>
        <template #expanded-row="{ item, columns }">
          <tr>
            <td v-if="item.selfTestDetails" :colspan="columns.length">
              <div class="pa-4">
                <b>Self test errors ({{ item.selfTestDetails.length }})</b>
                <ul v-for="testError in item.selfTestDetails" :key="testError">
                  <li class="ml-8">{{ testError.replace(' self-test', '') }}</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td v-if="item.fieldTestDetails" :colspan="columns.length">
              <div class="pa-4">
                <b>Field test errors ({{ item.fieldTestDetails.length }})</b>
                <ul v-for="testError in item.fieldTestDetails" :key="testError">
                  <li class="ml-8">{{ testError.replace(' self-test', '') }}</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td v-if="item.events" :colspan="columns.length">
              <div class="pa-4">
                <b>Debug events ({{ item.events.length }})</b>
                <ul v-for="event in item.events" :key="event">
                  <li class="ml-8">{{ event }}</li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
        <template #item.data-table-expand="{ isExpanded, item, toggleExpand, internalItem }">
          <template v-if="item.isExpandable">
            <v-btn v-if="!isExpanded(internalItem)" icon="mdi-chevron-down" @click="toggleExpand(internalItem)" />
            <v-btn v-if="isExpanded(internalItem)" icon="mdi-chevron-up" @click="toggleExpand(internalItem)" />
          </template>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import dayjs from 'dayjs'

  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { logEvent } from 'firebase/analytics'

  import { Debounce } from '@jouzen/outo-apps-toolkit'

  import { DateTime } from '#mixins/dateTime'

  import { nonSensitiveSummaryHeaders, summaryHeaders } from '#views/users/constants'

  import { AppStore, SummaryStore, UserStore } from '#stores'
  import { SummaryData } from '#stores/summary.types'

  @Component
  class SummaryV2 extends mixins(DateTime) {
    @Prop() public userUuid!: string

    public startDate = dayjs().subtract(14, 'day').format('YYYY-MM-DD')
    public endDate = dayjs().format('YYYY-MM-DD')
    public datesAreValid = false

    public expanded = JSON.parse(localStorage.OuraExpandedViews || '{}').summary

    public userStore = new UserStore()
    public summaryStore = new SummaryStore()
    public appStore = new AppStore()

    public get isSensitiveDataVisible() {
      return this.appStore.sensitiveDataVisible
    }

    public get headers() {
      return this.isSensitiveDataVisible ? summaryHeaders : nonSensitiveSummaryHeaders
    }

    public get waitingForData(): boolean {
      return this.summaryStore.waitingForData()
    }

    public get summaryDataError() {
      return this.summaryStore.summaryDataError
    }

    public get summaryData() {
      const data = this.summaryStore.summaryData

      const dataWithNoEmptyRows = data.filter((row) => {
        const { day: _, ...otherFields } = row
        const isNonEmpty = Object.values(otherFields).some((field) => field)
        return isNonEmpty
      })

      return dataWithNoEmptyRows.map((row) => ({ ...row, isExpandable: row.selfTestSuccess === false }))
    }

    @Watch('summaryData')
    protected onSummaryDataChanged(newSummaryData: SummaryData[]) {
      if (newSummaryData.length) {
        logEvent(this.$analytics, 'troubleshooter_available', {
          category: `User:Timeline:Dashboard Summary data V2 available`,
          action: 'Filters in Timeline:Dashboard SummaryV2 available',
          label: 'Filters in Timeline:Dashboard SummaryV2 available',
          page_title: 'Oura user',
          page_location: window.location.toString().split('?')[0],
        })
      }
    }

    @Watch('userUuid', { immediate: true })
    protected onUuidChanged() {
      this.updateData()
    }

    @Watch('expanded')
    protected onExpandedChanged(val: string) {
      const expands = JSON.parse(localStorage.OuraExpandedViews || '{}')
      expands.summary = val
      localStorage.OuraExpandedViews = JSON.stringify(expands)
    }

    @Watch('startDate')
    @Watch('endDate')
    @Watch('datesAreValid', { immediate: true })
    protected onDatesChanged() {
      if (this.datesAreValid) {
        this.updateData()
      }
    }

    @Debounce(1000)
    private updateData() {
      if (this.userUuid) {
        this.summaryStore.getSummaryData({
          userUuid: this.userUuid,
          from: this.startDate,
          to: this.endDate,
        })
      }
    }
  }

  export default toNative(SummaryV2)
</script>
